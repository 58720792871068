export const stakingAddress: { [key: number]: string } = {
  56: "0x738e0A131fdb8749e689dC296Cd9F23A2AE6EB7E",
};
export const tokenAddress = {
  56: "0x44f3e337C3c5EfEd9C957FCd7898F9A85C7A7623",
};

export const RPC_URL = {
  56: "https://bsc-dataseed3.binance.org",
};
