import React, { useEffect, useState } from "react";
import { Web3Button, useWeb3Modal } from "@web3modal/react";
import { useDarkMode } from "usehooks-ts";
import etherLogo from "../../assets/icons/ether.png";
import bscLogo from "../../assets/icons/bsc.png";
import nexusLogo from "../../assets/images/LogoImg.png";
import Logo from "../../assets/images/sidebarLogo.png";
// import { ReactComponent as ChevronDownIcon } from "../../assets/icons/chevron-down.svg";
import { ReactComponent as SearchIcon } from "../../assets/icons/search.svg";
import { ReactComponent as MenuIcon } from "../../assets/icons/menu.svg";
// import { ReactComponent as SettingIcon } from "../../assets/icons/setting.svg";
import { ReactComponent as MoonIcon } from "../../assets/icons/moon.svg";
import { ReactComponent as SunIcon } from "../../assets/icons/sun.svg";
import { ReactComponent as Close } from "../../assets/icons/close.svg";
import { ReactComponent as Home } from "../../assets/icons/home.svg";
import { ReactComponent as Live } from "../../assets/icons/live.svg";
import { ReactComponent as Pair } from "../../assets/icons/liveexplorer.svg";
import { AnimatePresence, motion } from "framer-motion";
import Dropdown from "../Dropdown";

import { ReactComponent as WallOfShame } from "../../assets/icons/wallofshame.svg";
import BitChain from "../../assets/images/LogoImg.png";

import "./Navigation.scss";
import { Link, NavLink } from "react-router-dom";
import { useChainId } from "wagmi";

const lists = [
  {
    label: "",
    leftIcon: bscLogo,
    chainId: 56,
  },
 
  // {
  //   label: "",
  //   leftIcon: nexusLogo,
  //   chainId: 97,
  // },
];

const Navigation: React.FC = () => {
  const { isDarkMode, toggle } = useDarkMode();
  const [openClose, setOpenClose] = useState(false);
  const { open } = useWeb3Modal();
  const chain = useChainId();

  const [selectedList, setSelectedList] = useState<{
    label: string;
    leftIcon?: string;
    chainId: number;
  } | null>({
    label: "",
    leftIcon: bscLogo,
    chainId: 56,
  });

  useEffect(() => {
    setSelectedList(lists.find((f) => f.chainId === chain) ?? null);
  }, [chain]);

  useEffect(() => {
    if (openClose) {
      document.body.style.overflowY = "hidden";
      document.body.style.height = "100vh";
    } else {
      document.body.style.overflowY = "initial";
      document.body.style.height = "initial";
    }
  }, [openClose]);

  useEffect(() => {
    if (isDarkMode) {
      if (document.body.classList.contains("light")) {
        document.body.classList.remove("light");
      }
      document.body.classList.add("dark");
    } else {
      if (document.body.classList.contains("dark")) {
        document.body.classList.remove("dark");
      }
      document.body.classList.add("light");
    }
  }, [isDarkMode]);

  return (
    <div className="navigation-wrapper">
      <div className="mx">
        <div className="navigation-container flex-item">
          <div className="allChains">
            <img src={Logo} height={60} />
          </div>
          {/* <div className="all-chains-drop-down flex-item">
            <p>All Chains</p>
            <ChevronDownIcon />
          </div> */}

          <div className="settings flex-item">
            {/* <div className="setting-icon circlebg">
              <SettingIcon />
            </div> */}
            {/* <div className="search-icon circlebg">
              <SearchIcon />
            </div> */}
            {/* <div className="star-icon circlebg">
              <StarIcon />
            </div> */}
            {/* <Dropdown
              label={"BNB"}
              setSelectedList={setSelectedList}
              selectedList={selectedList}
              lists={lists}
            /> */}

            <div className="web3-btn">
              <Web3Button />
            </div>
          </div>
        </div>

        <AnimatePresence>
          {openClose && (
            <motion.div  
              className="sidebar-backdrop"
              onClick={() => setOpenClose(false)}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              initial={{ opacity: 0 }}
            >
              <motion.div
                className="bar"
                onClick={(e: any) => e.stopPropagation()}
                animate={{ right: 0, transitionDelay: "-200ms" }}
                exit={{ right: -300 }}
                initial={{ right: -300 }}
              >
                <div className="header-side-bar">
                  <div
                    className="close-icon"
                    onClick={() => setOpenClose(false)}
                  >
                    <Close />
                  </div>
                </div>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default Navigation;
