import { tokenAddress } from "./address";
import { ethers, formatEther } from "ethers";

import ERC20Abi from "../utils/abis/ERC20ABI.json";

const getSigner = async (address: string) => {
  const win = window as any;
  const provider = new ethers.BrowserProvider(win.ethereum);
  const signer = await provider.getSigner(address);
  return signer;
};

export const getUserBalance = async (address: string, chainId: number) => {
  const signer = await getSigner(address);
  const contract = new ethers.Contract(
    tokenAddress[chainId as keyof typeof tokenAddress],
    ERC20Abi,
    signer
  );

  const balance = await contract.balanceOf(address);
  return Number(formatEther(balance));
};
